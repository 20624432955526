import { Proposal } from "../../types/proposal";

export const activeProposal: Proposal[] = [
  {
    id: 45,
    discordLink:
      "https://discord.com/channels/969228248552706078/1192044317179658250/1192044319729786890",
  },
  {
    id: 46,
    discordLink:
      "https://discord.com/channels/969228248552706078/1193832232272543785/1193832234881384458",
  },
];
